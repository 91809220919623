<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';
    export default {
        props: ['filter', 'totalCount', 'params','prime'],
        mixins: [Export],
        data() {
            var headers = [
                {header: 'name', key: 'name', label: 'Tên khóa đào tạo', width: 30},
                {header: 'edu_gender', key: 'edu_gender', label: 'Nội dung đào tạo giới', width: 30},
                {header: 'start_time', key: 'start_time', label: 'Ngày bắt đầu', width: 10},
                {header: 'end_time', key: 'end_time', label: 'Ngày kết thúc', width: 10},
                {header: 'site_name', key: 'site_name', label: 'Cơ sở đào tạo', width: 10},
            ];
            if (this.prime) {
                headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
            }
            return {
                service: '/edu',
                fileName: `dao_tao_cham_soc`,
                headers: headers
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);

                row = {};
                 if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["name"] = "Nhập định dạng chữ";
                row["edu_gender"] = "Nhập 01 hoặc nhiều giá trị từ 1 đến 2 (trong đó: " + this.$params.eduPatient.contentEduGenderOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["start_time"] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu";
                row["end_time"] = "Nhập định dạng ngày/tháng/năm (dd/mm/yyyy)";
                row["site_name"] = "Nhập định dạng chữ";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };
            },
            addRow(item, params) {
                let row = {
                    name: item.name,
                    edu_gender: this.getContentGenders(item.id),
                    start_time: item.start_time > 0 ? moment(item.start_time * 1000).format('DD/MM/YYYY') : '',
                    end_time: item.end_time > 0 ? moment(item.end_time * 1000).format('DD/MM/YYYY') : '',
                    site_name: item.site_name,
                };
                if (this.prime) {
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getContentGenders(ID) {
                let text = "";
                if (this.params.contentGenderTypes[ID]) {
                    for (let item of this.params.contentGenderTypes[ID]) {
                        text = text + this.$params.eduPatient.contentEduGenderOptions.find(o => o.value == item).text + "; ";
                    }
                }
                return text.substring(0, text.length - 2);
            },
        }
    }
</script>